document.addEventListener('DOMContentLoaded', () => {
  let gtag
  let gtagAvailable = false
  const checkGtagAvailability = function () {
    if (window.gtag) {
      gtagAvailable = true
      console.log('gtag est disponible. Exécution du reste du code...')
      gtag = window.gtag
      executeTrackingCode()
    } else {
      console.log('gtag non disponible')
      setTimeout(checkGtagAvailability, 100)
    }
  }
  checkGtagAvailability()

  const executeTrackingCode = function () {
    const devisButtons = document.querySelectorAll(
      'a[href^="https://www.storeconfort.fr/nous-contacter"]'
    )
    if (devisButtons.length > 0) {
      devisButtons.forEach((button) => {
        button.addEventListener('click', () => {
          if (typeof gtag !== 'undefined') {
            gtag('event', 'devis_button_click', {
              event_category: 'engagement',
              event_label: 'Devis Button',
              value: button.href
            })
          }
        })
      })
    }
  }
})
